import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BADART_URL } from '../config';

const EditCollection = ({ collection, onClose, refreshCollectionList, starsAddress }) => {
  const { id: paramId } = useParams();
  const id = collection?.id || paramId;
  const [name, setName] = useState(collection?.name || '');
  const [description, setDescription] = useState(collection?.description || '');
  const [walletAdd, setWalletAdd] = useState(collection?.walletAdd || '');
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoUrl, setCoverPhotoUrl] = useState(collection?.coverPhoto ? `${BADART_URL}/${collection.coverPhoto}` : '');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    if (!collection) {
      fetch(`${BADART_URL}/api.php?id=${id}`)
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            const collection = data[0];
            console.log('Fetched data:', collection); // Logowanie danych
            setName(collection.name || '');
            setDescription(collection.description || '');
            setWalletAdd(collection.walletAdd || '');
            if (collection.coverPhoto) {
              setCoverPhotoUrl(`${BADART_URL}/${collection.coverPhoto}`);
            }
          }
        })
        .catch(error => console.error('Error fetching collection:', error));
    }
  }, [id, collection]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    formData.append('description', description);
    if (coverPhoto) {
      formData.append('cover', coverPhoto); // Keep as 'cover' to match PHP $_FILES key
    }

    fetch(`${BADART_URL}/edit_collection.php`, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setMessage(data.message);
      setMessageType(data.success ? 'success' : 'error');
      if (data.message === "Collection updated successfully") {
        refreshCollectionList(starsAddress);
        if (onClose) onClose();
      }
    })
    .catch(error => {
      console.error('Error editing collection:', error);
      setMessage('Error editing collection');
      setMessageType('error');
    });
  };

  return (
    <div className='container'>
      {message && (
        <div className={`alert alert-${messageType}`}>
          {message}
        </div>
      )}
      <div className='container-fluid'>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-4'>
              {coverPhotoUrl && (
                <div className="cover-preview">
                  <img src={coverPhotoUrl} alt="Cover" className='img-fluid rounded mb-3' />
                </div>
              )}
            </div>
            <div className='col-8'>
              <div className="form-group">
                <label htmlFor="name">Collection Name</label>
                <input
                  type="text"
                  className="form-control mb-3 mt-3"
                  id="name"
                  autocomplete="off"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-control mb-3 mt-3"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cover">Change cover image</label>
                <input
                  type="file"
                  className="form-control mt-3"
                  id="cover"
                  onChange={(e) => setCoverPhoto(e.target.files[0])}
                />
              </div>
              <button type="submit" className="btn btn-dark mt-4 text-white">
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCollection;