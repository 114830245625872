import React from 'react';

const XmasPage = () => {
  const participants = [
  {
    stars: 'stars1ap6...5f6a',
    twitter: '@nftdreamerstar',
    joined: '2024-12-13',
    prize: 'https://www.stargaze.zone/m/bat-kids/190',
    result: 'https://simpliers.com/en/giveaway/result/TAOQOV'
  }
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card bg-transparent custom-border">
            <div className="card-body">
              <h2 className="mb-4">
                <i className="bi bi-snow2 text-primary me-2"></i>
                Christmas Event Winners
              </h2>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Stargaze Address</th>
                      <th scope="col">Twitter</th>
                      <th scope="col">Date</th>
                      <th scope="col">Prize</th>
                      <th scope="col">Giveaway Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants.map((participant, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <small>{participant.stars}</small>
                        </td>
                        <td>
                          <a href={`https://twitter.com/${participant.twitter.substring(1)}`} 
                             target="_blank" 
                             rel="noopener noreferrer">
                            {participant.twitter}
                          </a>
                        </td>
                        <td>{participant.joined}</td>
                        <td>
                          <a href={participant.prize} target="_blank" rel="noopener noreferrer">
                            Prize Link
                          </a>
                        </td>
                        <td>
                          <a href={participant.result} target="_blank" rel="noopener noreferrer">
                            Result Link
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XmasPage;
