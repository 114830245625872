/**
 * Shortens a wallet address for display
 */
export const shortenAddress = (address) => {
  if (address?.length > 8) {
    return `${address.slice(0, 9)}...${address.slice(-3)}`;
  }
  return address;
};

/**
 * Copies text to clipboard and shows success indicator
 */
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      const copyAddElement = document.getElementById('copy_add');
      if (copyAddElement) {
        copyAddElement.classList.add('text-success');
      }
    })
    .catch(err => console.error('Error copying text to clipboard:', err));
};

/**
 * Shows a temporary message to the user
 */
export const showMessage = (setMessage, setMessageType, msg, type) => {
  setMessage(msg);
  setMessageType(type);
  setTimeout(() => {
    setMessage(null);
    setMessageType(null);
  }, 5000);
};

/**
 * Ends the current wallet session and cleans up storage
 */
export const endSession = (setStarsAddress, setOwnershipProved, setButtonText, showMessage, navigate) => {
  setStarsAddress(null);
  setOwnershipProved(false);
  sessionStorage.removeItem('starsAddress');
  sessionStorage.removeItem('ownershipProved');
  sessionStorage.removeItem('keplrSignature');
  sessionStorage.removeItem('activeWallet'); // Add this line
  if (typeof setButtonText === 'function') {
    setButtonText('Connect wallet');
  }
  showMessage("Session ended successfully!", "success");
  if (navigate) {
    navigate('/');
  }
};

/**
 * Base function for proving account ownership
 */
export const baseProveAccountOwnership = async (
  walletWindow,
  chainId,
  setStarsAddress,
  setOwnershipProved,
  showMessageCallback,
  getOfflineSigner,
  walletType // Add walletType parameter
) => {
  if (!walletWindow) {
    showMessageCallback(`Please install ${walletType} extension`, "warning");
    return;
  }

  try {
    await walletWindow.enable(chainId);
    const offlineSigner = getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    const address = accounts[0].address;

    const message = "Prove account ownership";
    // Use the specific wallet's signArbitrary
    await walletWindow.signArbitrary(chainId, address, message);
    
    setStarsAddress(address);
    setOwnershipProved(true);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('ownershipProved', 'true');
    sessionStorage.setItem('activeWallet', walletType); // Store active wallet type
    showMessageCallback("Account ownership proved successfully!", "success");
  } catch (error) {
    console.error(`Error proving ${walletType} account ownership:`, error);
    setOwnershipProved(false);
    sessionStorage.setItem('ownershipProved', 'false');
    showMessageCallback(`Error proving ${walletType} account ownership`, "error");
  }
};

/**
 * Base function for connecting wallet
 */
export const baseConnectWallet = async (
  walletWindow, 
  chainId, 
  setStarsAddress, 
  getOfflineSigner,
  walletType,
  showMessageCallback // Add showMessageCallback parameter
) => {
  if (!walletWindow) {
    if (showMessageCallback) {
      showMessageCallback(`Please install ${walletType} extension`, "warning");
    }
    return;
  }
  try {
    await walletWindow.enable(chainId);
    const offlineSigner = getOfflineSigner(chainId);
    const accounts = await offlineSigner.getAccounts();
    const address = accounts[0].address;
    setStarsAddress(address);
    sessionStorage.setItem('starsAddress', address);
    sessionStorage.setItem('activeWallet', walletType); // Store active wallet type
  } catch (error) {
    console.error(`Error connecting to ${walletType}:`, error);
  }
};

/**
 * Check if specific wallet is available
 */
export const isWalletAvailable = (walletType) => {
  try {
    if (walletType === 'Leap') {
      return typeof window.leap !== 'undefined';
    } else if (walletType === 'Keplr') {
      return typeof window.keplr !== 'undefined';
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * Wait for wallet to be available
 */
export const waitForWallet = async (walletType, maxAttempts = 10) => {
  // First check if the specified wallet is already available
  if (isWalletAvailable(walletType)) {
    return true;
  }

  // If Leap is not available but Keplr is requested and available, return true
  if (walletType === 'Keplr' && isWalletAvailable('Keplr')) {
    return true;
  }

  // If neither is immediately available, wait and check again
  for (let i = 0; i < maxAttempts; i++) {
    await new Promise(resolve => setTimeout(resolve, 500));
    
    if (isWalletAvailable(walletType)) {
      return true;
    }
    
    // If waiting for Keplr and it becomes available, return true
    if (walletType === 'Keplr' && isWalletAvailable('Keplr')) {
      return true;
    }
  }
  
  return false;
};

/**
 * Initialize active wallet from session storage
 */
export const initializeWallet = async (starsAddress, setStarsAddress) => {
  const activeWallet = sessionStorage.getItem('activeWallet');
  if (starsAddress && activeWallet) {
    const isAvailable = await waitForWallet(activeWallet);
    if (!isAvailable) {
      console.warn(`${activeWallet} wallet not available`);
      return;
    }

    const walletWindow = activeWallet === 'Leap' ? window.leap : window.keplr;
    try {
      await baseConnectWallet(
        walletWindow,
        "stargaze-1",
        setStarsAddress,
        (chainId) => walletWindow.getOfflineSigner(chainId),
        activeWallet
      );
    } catch (error) {
      console.error('Error reconnecting wallet:', error);
      clearWalletSession();
      setStarsAddress(null);
    }
  }
};

/**
 * Clear all wallet-related session storage items
 */
export const clearWalletSession = () => {
  sessionStorage.removeItem('starsAddress');
  sessionStorage.removeItem('ownershipProved');
  sessionStorage.removeItem('activeWallet');
  sessionStorage.removeItem('keplrSignature');
};

/**
 * Handle wallet changes and reconnection
 */
export const setupWalletChangeHandlers = (setStarsAddress, setOwnershipProved, navigate, callback) => {
  const handleWalletChange = async () => {
    const addr = sessionStorage.getItem('starsAddress');
    const activeWallet = sessionStorage.getItem('activeWallet');

    if (!window.keplr && !window.leap) {
      // If no wallet is available, end session
      endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
      return;
    }

    if (addr && activeWallet) {
      const walletWindow = activeWallet === 'Leap' ? window.leap : window.keplr;
      try {
        // Try to get accounts to verify wallet is still connected
        await walletWindow.enable("stargaze-1");
        const offlineSigner = walletWindow.getOfflineSigner("stargaze-1");
        const accounts = await offlineSigner.getAccounts();
        
        if (accounts[0].address !== addr) {
          // If address changed, end session
          endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
        } else {
          setStarsAddress(addr);
          if (callback) await callback();
        }
      } catch (error) {
        console.error('Error checking wallet connection:', error);
        endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
      }
    } else {
      endSession(setStarsAddress, setOwnershipProved, null, () => {}, navigate);
    }
  };

  const onKeplrChange = () => {
    console.log('Keplr wallet changed');
    handleWalletChange();
  };

  const onLeapChange = () => {
    console.log('Leap wallet changed');
    handleWalletChange();
  };

  window.addEventListener("keplr_keystorechange", onKeplrChange);
  window.addEventListener("leap_keystorechange", onLeapChange);

  return () => {
    window.removeEventListener("keplr_keystorechange", onKeplrChange);
    window.removeEventListener("leap_keystorechange", onLeapChange);
  };
};
