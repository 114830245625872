import axios from 'axios';
import { BADART_URL } from '../config';

export const fetchFiles = async (collectionId) => {
  try {
    const response = await axios.get(`${BADART_URL}/get_files.php?collectionId=${collectionId}`);
    return response.data.files.map(file => ({ ...file, status: 'Uploaded' }));
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};

export const fetchTraits = async (collectionId) => {
  try {
    const response = await axios.get(`${BADART_URL}/get_traits.php?collectionId=${collectionId}`);
    return response.data.traits;
  } catch (error) {
    console.error('Error fetching traits:', error);
    throw error;
  }
};

export const fetchAssignedTraits = async (collectionId) => {
  try {
    const response = await axios.get(`${BADART_URL}/get_assigned_traits.php?collectionId=${collectionId}`);
    const traitsByFile = {};
    
    if (response.data && Array.isArray(response.data.assignments)) {
      response.data.assignments.forEach(assignment => {
        if (!traitsByFile[assignment.file_id]) {
          traitsByFile[assignment.file_id] = [];
        }
        traitsByFile[assignment.file_id].push(assignment);
      });
      return traitsByFile;
    } else {
      console.warn('Unexpected response format:', response.data);
      return {};
    }
  } catch (error) {
    console.error('Error fetching assigned traits:', error);
    return {};
  }
};

export const fetchCollectionDetails = async (collectionId, starsAddress) => {
  try {
    const response = await axios.get(`${BADART_URL}/get_collection.php?collectionId=${collectionId}&walletAdd=${starsAddress}`);
    return {
      name: response.data.name || '',
      description: response.data.description || ''
    };
  } catch (error) {
    console.error('Error fetching collection details:', error);
    throw error;
  }
};

export const getValidFileIds = async (collectionId) => {
  try {
    const response = await axios.get(`${BADART_URL}/get_files.php?collectionId=${collectionId}`);
    return response.data.files.map(file => parseInt(file.id)); // Parse as integers
  } catch (error) {
    console.error('Error fetching valid file IDs:', error);
    throw error;
  }
};

export const getOrderedFileIds = async (collectionId) => {
  try {
    const response = await axios.get(`${BADART_URL}/get_files.php?collectionId=${collectionId}`);
    // Sort files by creation date and map to their IDs
    return response.data.files
      .sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate))
      .map((file, index) => ({
        displayId: index + 1,
        actualId: parseInt(file.id)
      }));
  } catch (error) {
    console.error('Error fetching ordered file IDs:', error);
    throw error;
  }
};
