const collectionAddresses = [
  {
    collectionAddr: 'stars1ff46le9ch4khue9y95c3fauj5p63wqk5yru28ayten6anpfvtdmq2f2l6m',
    cover: 'https://ipfs-gw.stargaze-apis.com/ipfs/bafybeifqgjhuikv4eaxpybjrvgtdn2dpxcm4pp3ggydak6t6eprymn2hva/cover.jpg',
    link: 'https://www.stargaze.zone/m/bat-kids/tokens',
    title: 'Bat Kids',
    multiplier: 250
  },
  {
    collectionAddr: 'stars1ewg2a87mhv9u80rdrewsru70han29hxg7dn4mhuvfgg95pzyjwds977a5m',
    cover: 'https://ipfs-gw.stargaze-apis.com/ipfs/bafybeihk4wmyvgz3gue6esbehw5d4tao55rfa45o6rirviszgar2y6vora/cover.jpg',
    link: 'https://www.stargaze.zone/m/bad-morning/tokens',
    title: 'Bad morning',
    multiplier: 150
  },
  {
    collectionAddr: 'stars15re2nvnrsgx2467vge89qn8pz30ug9l26qerfxv6qk83l47td66qny7jpx',
    cover: 'https://ipfs-gw.stargaze-apis.com/ipfs/bafybeihocsxofh66rssfnfzplwb7lmugjlddhshjfiutacn7iug6sa5vfi/cover.jpg',
    link: 'https://www.stargaze.zone/m/lazy-sloth/tokens',
    title: 'Lazy Sloth Club',
    multiplier: 100
  },
  {
    collectionAddr: 'stars1tdlnj8dacmluxfwqsreq28l9emsgrkamgzf7ylu7mcck3mtjed9qp0gyzw',
    cover: 'img/tape_cover.gif',
    link: 'https://www.stargaze.zone/m/stars1tdlnj8dacmluxfwqsreq28l9emsgrkamgzf7ylu7mcck3mtjed9qp0gyzw/tokens',
    title: 'Join revolution!',
    multiplier: 50
  }
];

export default collectionAddresses;