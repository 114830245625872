import React, { useState, useEffect, useCallback } from 'react';
import { BADART_URL } from '../config';
import Toast from '../components/Toast';
import ConfirmationToast from '../components/ConfirmationToast';

const Borrow = ({ starsAddress = '', totalCount = 0 }) => {
    const [address, setAddress] = useState('');
    const [credits, setCredits] = useState('');
    const [toast, setToast] = useState({ message: '', type: '' });
    const [borrowRecords, setBorrowRecords] = useState([]);
    const [totalBorrowed, setTotalBorrowed] = useState(0);
    const [confirmDelete, setConfirmDelete] = useState(null);

    const fetchBorrowRecords = useCallback(async () => {
        try {
            const response = await fetch(`${BADART_URL}/select_borrow.php?walletAdd=${starsAddress}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setBorrowRecords(data.records || []);
            
            // Calculate total borrowed credits
            const total = (data.records || []).reduce((sum, record) => 
                sum + parseInt(record.credits, 10), 0);
            setTotalBorrowed(total);
        } catch (error) {
            setToast({
                message: 'Failed to fetch records: ' + error.message,
                type: 'error'
            });
        }
    }, [starsAddress]);

    useEffect(() => {
        if (starsAddress) {
            fetchBorrowRecords();
        }
    }, [starsAddress, fetchBorrowRecords]);

    const validateStarsAddress = (address) => {
        const starsAddressRegex = /^stars[0-9a-z]{39}$/;
        return starsAddressRegex.test(address);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!starsAddress) {
            setToast({
                message: 'Please connect your wallet first',
                type: 'warning'
            });
            return;
        }

        // Validate recipient address format
        if (!validateStarsAddress(address)) {
            setToast({
                message: 'Invalid recipient address format. Must start with "stars" followed by 39 characters.',
                type: 'warning'
            });
            return;
        }

        // Prevent borrowing to own address
        if (address.toLowerCase() === starsAddress.toLowerCase()) {
            setToast({
                message: 'Cannot borrow to your own wallet address',
                type: 'warning'
            });
            return;
        }

        // Check if trying to borrow more than available
        const requestedCredits = parseInt(credits, 10);
        const availableCredits = totalCount - totalBorrowed;
        
        if (requestedCredits > availableCredits) {
            setToast({
                message: `Cannot borrow more than available credits (${availableCredits})`,
                type: 'warning'
            });
            return;
        }

        try {
            const response = await fetch(`${BADART_URL}/add_borrow.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    address, 
                    credits: requestedCredits,
                    walletAdd: starsAddress,
                    totalCount: totalCount,
                    totalBorrowed: totalBorrowed
                }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            setToast({
                message: data.message,
                type: 'success'
            });
            // Clear form after successful submission
            setAddress('');
            setCredits('');
            
            // Refresh the borrow records list
            await fetchBorrowRecords();
        } catch (error) {
            setToast({
                message: 'Failed to submit: ' + error.message,
                type: 'error'
            });
            console.error('Error:', error);
        }
    };

    const handleDelete = async (id) => {
        setConfirmDelete(id);
    };

    const confirmDeleteAction = async () => {
        const id = confirmDelete;
        setConfirmDelete(null);

        try {
            const response = await fetch(`${BADART_URL}/delete_borrow.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    id,
                    walletAdd: starsAddress
                }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            setToast({
                message: data.message,
                type: 'success'
            });
            
            // Refresh the list after deletion
            await fetchBorrowRecords();
        } catch (error) {
            setToast({
                message: 'Failed to delete record: ' + error.message,
                type: 'error'
            });
            console.error('Error:', error);
        }
    };

    return (
        <div className="container-fluid mb-5 pb-5">
            <div className="row pb-5 pt-4">
            <div className="alert alert-warning d-block" role="alert">
                Note: This functionality is currently in the testing stage and may not work globally yet.
            </div>
            <h3 className="mb-2">Borrow NFT credits</h3>
            <p className='mb-6'>If you'd like to keep your tokens in your wallet, you can lend credits to another user.</p>

 
                    <div className="col-md-4 rounded custom-border pt-3">
                        <span className="badge fs-6 bg-light text-dark border-right-0">Total: {totalCount}</span>
                        <span className="badge fs-6 bg-warning rounded-0 text-dark">Available: {totalCount - totalBorrowed}</span>
                        <span className="badge fs-6 bg-danger text-white border-left-0">Borrowed: {totalBorrowed}</span>

                    {/* Form section */}
                    <form onSubmit={handleSubmit} className="mt-3 mb-3">
                        <div className="form-group mb-3">
                            <label htmlFor="address" className='fs-6 mb-2 mt-2'>Recipient Address</label>
                            <input
                                type="text"
                                className="form-control"
                                id="address"
                                value={address}
                                autocomplete="off"
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Enter stars address"
                                pattern="^stars[0-9a-z]{39}$"
                                title="Must be a valid Stargaze address starting with 'stars' followed by 39 characters"
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="credits"  className='fs-6 mb-2 mt-2'>Credits amount you wanna borrow</label>
                            <input
                                type="number"
                                className="form-control"
                                id="credits"
                                value={credits}
                                autocomplete="off"
                                onChange={(e) => setCredits(e.target.value)}
                                max={totalCount - totalBorrowed}
                                required
                            />
                            <small className="text-muted">
                                Maximum available: {totalCount - totalBorrowed}
                            </small>
                        </div>
                        <button type="submit" className="btn btn-dark">Borrow credits</button>
                    </form>
                </div>
                <div className="col-md-8">
                    {/* Records section */}
                    <div className="mt-0 rounded">
                        {borrowRecords.length > 0 ? (
                            <div className="table-responsive rounded">
                                <table className="table table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Borrowed to address</th>
                                            <th>Credits</th>
                                            <th>Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {borrowRecords.map((record) => (
                                            <tr key={record.id}>
                                                <td>{record.address}</td>
                                                <td>{record.credits}</td>
                                                <td>{new Date(record.created_at).toLocaleDateString()}</td>
                                                <td>
                                                    <button 
                                                        onClick={() => handleDelete(record.id)}
                                                        className="btn btn-danger btn-sm"
                                                    >
                                                        <i className="bi bi-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-muted">No borrow records found.</p>
                        )}
                    </div>
                </div>
            </div>
            
            <Toast 
                message={toast.message}
                type={toast.type}
                onClose={() => setToast({ message: '', type: '' })}
            />

            {confirmDelete && (
                <ConfirmationToast
                    message="Are you sure you want to delete this record?"
                    onConfirm={confirmDeleteAction}
                    onCancel={() => setConfirmDelete(null)}
                />
            )}
        </div>
    );
};

export default Borrow;