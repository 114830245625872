import React from 'react';

const ConfirmationToast = ({ message, onConfirm, onCancel }) => {
  return (
    <>
      <div className="modal-backdrop" onClick={onCancel}></div>
      <div className="toast-container center">
        <div className="toast confirmation-toast show" role="alert">
          <div className="toast-header">
            <i className="bi bi-exclamation-triangle me-2"></i>
            <strong className="me-auto">Confirm Action</strong>
          </div>
          <div className="toast-body">
            <p>{message}</p>
            <div className="btn-group">
              <button 
                type="button" 
                className="btn btn-secondary" 
                onClick={onCancel}
              >
                Cancel
              </button>
              <button 
                type="button" 
                className="btn btn-danger" 
                onClick={onConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationToast;
