import React from 'react';
import PropTypes from 'prop-types';
import { fetchTokens } from './TokenList';

const TokenDisplay = React.memo(({ starsAddress, collectionAddr, title, cover, link, multiplier, updateTotalCount, updateIsOwned, hideTokenCount }) => {
  const [tokenData, setTokenData] = React.useState({ count: 0, floorPrice: 0 });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const loadTokens = async () => {
      setLoading(true);
      try {
        const data = await fetchTokens({ starsAddress: starsAddress || "", collectionAddr });
        setTokenData(data);
        updateTotalCount(data.count * multiplier);
        updateIsOwned(data.count > 0);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (collectionAddr) {
      loadTokens();
    }
  }, [starsAddress, collectionAddr, multiplier, updateTotalCount, updateIsOwned]);

  return (
    <div className="col-5 col-md-2 float-start mb-4 me-4 text-center">
      <article className={`card text-center border-light-subtle ${tokenData.count > 0 ? 'opacity-100' : 'opacity-25'}`}>
        <div className="d-flex flex-column">
          <a href={link} className="card-link my-shadow-2">
            <img className="rounded-top img-fluid" src={cover} alt={title} loading="lazy" />
          </a>
          <div className="card-body p-0 d-flex flex-column">
            <h6 className="card-title mt-4 mb-2 fw-bold fs-5">{title}</h6>
            {!hideTokenCount && <p className="fs-6 fw-bold text-secondary mb-2">{tokenData.count} NFT</p>}
            {tokenData.floorPrice > 0 && <p className="fs-6 text-muted"><span className='small d-inline p-0 me-1'>Floor: {tokenData.floorPrice / 1000000}</span><span className='custom-color fs-5 p-0'>⍟</span></p>}
            <a href={link} className="btn bg-dark col-12 text-white mt-auto rounded-top-0" role="button">{tokenData.count > 0 ? 'Get more!' : 'Get some!'}</a>
          </div>
        </div>
      </article>
      {loading && <div className="text-center mt-2">Loading...</div>}
      <span className="me-1 ms-1 fw-bold badge bg-transparent custom-border fs-5 mt-3 text-dark">x{multiplier}</span>
    </div>
  );
});

TokenDisplay.propTypes = {
  starsAddress: PropTypes.string.isRequired,
  collectionAddr: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  multiplier: PropTypes.number.isRequired,
  updateTotalCount: PropTypes.func.isRequired,
  updateIsOwned: PropTypes.func.isRequired,
  hideTokenCount: PropTypes.bool
};

export default TokenDisplay;
