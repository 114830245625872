import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { v4 as uuidv4 } from 'uuid';

// Add UUID polyfill
if (typeof crypto === 'undefined' || typeof crypto.randomUUID !== 'function') {
  if (typeof crypto === 'undefined') {
    window.crypto = {};
  }
  crypto.randomUUID = uuidv4;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);