import React, { useState } from 'react';

const Faq = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqItems = [
    {
      question: "What is an NFT?",
      answer: "An NFT (Non-Fungible Token) is a unique digital token that represents ownership of a specific virtual or physical asset. NFTs are stored on a blockchain, ensuring transparency and immutability of their data."
    },
    {
      question: "How does an NFT work?",
      answer: "An NFT functions as a certificate of authenticity for an asset. Each NFT has a unique identifier that distinguishes it from others. Ownership details and transaction history are securely recorded on the blockchain."
    },
    {
      question: "What can be an NFT?",
      answer: <div>
        NFTs can represent various assets, such as:
        <ul className="mt-2 ms-4">
          <li>Digital art (illustrations, animations, music)</li>
          <li>In-game items (skins, weapons, characters)</li>
          <li>Collectibles (trading cards, memes)</li>
          <li>Tickets and access passes (for events or experiences)</li>
          <li>Rights to physical goods (paintings, real estate)</li>
        </ul>
      </div>
    },
    {
      question: "What is an NFT made of?",
      answer: <div>
        An NFT consists of:
        <ul className="mt-2 ms-4">
          <li>Metadata: Describes the NFT's properties, such as its name, creator, and unique ID</li>
          <li>Blockchain address: The location where the NFT exists</li>
          <li>Asset file: For example, an image, audio, or video file</li>
          <li>Smart contract: Defines ownership and transfer rules for the NFT</li>
        </ul>
      </div>
    },
    {
      question: "What are NFT metadata?",
      answer: <div>
        NFT metadata provide details about the token and may include:
        <ul className="mt-2 ms-4">
          <li>Title and description</li>
          <li>Creator and owner</li>
          <li>Attributes (e.g., color, rarity level)</li>
          <li>Asset file location (e.g., a link to IPFS)</li>
        </ul>
      </div>
    },
    {
      question: "Can NFTs expire?",
      answer: "NFTs are generally permanent because they exist on a blockchain. However, the associated asset file (e.g., an image) might become inaccessible or be removed if it is not stored properly in a decentralized system like IPFS."
    }
  ];

  return (
    <div className="container my-5">
       <div className="row justify-content-between">
        <div className="col-lg-4">
          <h3 className='mb-3'>Understanding NFTs</h3>
          <p className="mb-4">
            Welcome to our comprehensive FAQ section about NFTs. Here you'll find answers to the most common questions about Non-Fungible Tokens, their creation, usage, and importance in the digital world.
          </p>
          <p>
            Whether you're a creator, collector, or just curious about NFTs, this guide will help you understand the fundamental concepts and technical aspects of NFT technology.
          </p>
        </div>
        <div className="col-lg-7">
          <div className="faq-accordion">
            {faqItems.map((item, index) => (
              <div className="card" key={index}>
                <div 
                  className="card-header d-flex justify-content-between align-items-center"
                  role="button"
                  onClick={() => toggleAccordion(index)}
                >
                  <h5 className="mb-0">{item.question}</h5>
                  <span className={`toggle-icon ${openItem === index ? 'rotate-180' : ''}`}>
                    ▾
                  </span>
                </div>
                <div className={`collapse ${openItem === index ? 'show' : ''}`}>
                  <div className="card-body">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='custom-border rounded p-3 my-2 text-center'>more educational content soon</div>
          </div>
        </div>
    </div>
  );
};

export default Faq;