import React, { useState, useEffect, useRef, useCallback } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { getTokenCount } from './TokenList';

// Components
import NavLink from './inc/NavLink';
import Header from './inc/header';
import Footer from './inc/footer';
import AddCollectionForm from './collections/AddCollectionForm';
import CollectionList, { refreshCollectionList } from './collections/CollectionList';
import ApplyForm from './apply/applyForm';
import ApplyList from './apply/applyList';
import Randomizer from './randomizer/randomizer';
import TokenDisplay from './TokenDisplay';
import CollectionFileUpload from './collections/CollectionFileUpload';
import TicketSystem from './ticket/TicketSystem';
import Borrow from './borrow/borrowTokens';
import EditCollection from './collections/EditCollection';
import HomePage from './homePage';
import Toast from './components/Toast';
import Faq from './Faq';
import Renameizer from './renameizer/renameizer';
import XmasPage from './XmasPage';

// Integrations & Utilities
import * as KeplrIntegration from './wallets/KeplrIntegration';
import * as LeapIntegration from './wallets/LeapIntegration';
import * as Helpers from './ext/helpers';
import collectionAddresses from './collections/collectionAddresses';
import { waitForWallet, initializeWallet, setupWalletChangeHandlers } from './wallets/walletUtils';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './ext/App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Add this line

const App = () => {
  // State management
  const [message, setMessage] = useState('');
  const [collections, setCollections] = useState([]);
  const [messageType, setMessageType] = useState('');
  const [starsAddress, setStarsAddress] = useState(sessionStorage.getItem('starsAddress') || null);
  const [ownershipProved, setOwnershipProved] = useState(sessionStorage.getItem('ownershipProved') === 'true');
  const [totalCount, setTotalCount] = useState(0);
  const collectionCountsRef = useRef({});
  const [ownedCollectionsCount, setOwnedCollectionsCount] = useState(0);
  const isOwnedRef = useRef({});

  // Define initializeCounts with useCallback before using it in useEffect
  const initializeCounts = useCallback(async () => {
    if (!starsAddress) return;
    
    for (const collection of collectionAddresses) {
      try {
        const { count } = await getTokenCount(starsAddress, collection.collectionAddr);
        // Update total count
        updateTotalCount(collection.collectionAddr, count * (collection.multiplier || 1));
        // Update ownership status
        updateIsOwned(collection.collectionAddr, count > 0);
      } catch (error) {
        console.error(`Error fetching counts for ${collection.collectionAddr}:`, error);
      }
    }
  }, [starsAddress]); // Add dependencies

  // Effects
  useEffect(() => {
    setTotalCount(0);
  }, []);

  useEffect(() => {
    if (starsAddress) {
      initializeCounts();
      refreshCollectionList(starsAddress, setCollections);
    }
  }, [starsAddress, initializeCounts]);

  // Replace the old wallet initialization effect with this:
  useEffect(() => {
    // Initialize wallet
    initializeWallet(starsAddress, setStarsAddress);
    
    // Setup wallet change handlers with all necessary parameters
    const cleanup = setupWalletChangeHandlers(
      setStarsAddress,
      setOwnershipProved,
      null, // navigate is not needed at App level
      null  // no callback needed at App level
    );
    
    return cleanup;
  }, [starsAddress]);

  // Helper functions
  const updateTotalCount = (collectionAddr, count) => {
    collectionCountsRef.current[collectionAddr] = count;
    const newTotal = Object.values(collectionCountsRef.current).reduce((sum, curr) => sum + curr, 0);
    setTotalCount(newTotal);
  };

  // Add new function to update owned collections count
  const updateIsOwned = (collectionAddr, owned) => {
    isOwnedRef.current[collectionAddr] = owned;
    const ownedCount = Object.values(isOwnedRef.current).filter(Boolean).length;
    setOwnedCollectionsCount(ownedCount);
  };

  // Route components mapping
  const routeComponents = {
    '/randomizer': <Randomizer />,
    '/vote': <ApplyList />,
    '/apply': <ApplyForm starsAddress={starsAddress} />,
    '/tickets': <TicketSystem />,
  };

  return (
    <Router>
      <main className="container-fluid container-custom mt-4 my-3 my-shadow">
        <Header 
          ownershipProved={ownershipProved}
          starsAddress={starsAddress}
          setStarsAddress={setStarsAddress}
          setOwnershipProved={setOwnershipProved}
          setMessage={setMessage}
          setMessageType={setMessageType}
          setTotalCount={setTotalCount}
        />

        <div className="container-fluid my-shadow bg-light rounded mb-4">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid p-0">
              <button 
                className="navbar-toggler ms-auto" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <NavLink to="/"><i className="bi bi-house-door-fill me-2"></i>Home</NavLink>
                  {ownershipProved ? (
                    <>
                      <NavLink to="/collections"><i className="bi bi-diagram-3-fill me-2"></i>META Editor <sup className='text-primary'>[JSON]</sup></NavLink>
                      <NavLink to="/renameizer"><i className="bi bi-pencil me-2"></i>NFT Rename <sup className='text-primary'></sup></NavLink>
                    </>
                  ) : null}
                  <NavLink to="/xmas"><i className="bi bi-snow2 me-2"></i>Xmas Winners List</NavLink>
                  <NavLink to="/faq"><i className="bi bi-stack me-2"></i>FAQ</NavLink>
                </ul>

                <ul className="navbar-nav">
                  {ownershipProved ? (
                    <NavLink to="/vote"><i className="bi bi-check-square me-2"></i>Vote</NavLink>
                  ) : null}
                  <NavLink to="/apply"><i className="bi bi-pencil-square me-2"></i>Apply</NavLink>
                </ul>
              </div>
            </div>
          </nav>
        </div>

          {/* Replace alert with Toast */}
          <Toast 
            message={message} 
            type={messageType} 
            onClose={() => {
              setMessage(null);
              setMessageType(null);
            }} 
          />

        {/* Routes */}
        <Routes>
          <Route path="/" element={
            <div className="container-fluid">
              {!ownershipProved ? (
                <HomePage />
              ) : (
                <div className="row portfolio">
                  <div className="col-12">
                    <div className="row justify-content-center g-4">
                      <div className="col-6">
                        <div className="custom-border rounded p-4 h-100 bg-transparent">
                          <div className="d-flex align-items-center mb-3">
                            <i className="bi bi-calendar2-check fs-2 me-3"></i>
                            <h4 className="mb-0">Your supported collections: 
                              <span className="badge bg-dark ms-2">{ownedCollectionsCount}</span>
                            </h4>
                          </div>
                          <div className="mt-3">
                            <p className="lead">Supported collections match your wallet holdings.</p>
                            <p className="mb-4">The more partner collections you hold in your wallet, the more features you unlock in the app. For instance, if you have two partner collections, such as <strong>Bad Morning</strong> or <strong>Lazy Sloth Club</strong>, you can manage <strong>two collections</strong> within the app.</p>
                            <a href="/#/collections" className="btn btn-dark">Create NFT Collection</a>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="custom-border rounded p-4 h-100 bg-transparent">
                          <div className="d-flex align-items-center mb-3">
                            <i className="bi bi-wallet fs-2 me-3"></i>
                            <h4 className="mb-0">Total credits: 
                              <span className="badge bg-dark ms-2">{totalCount}</span>
                            </h4>
                          </div>
                          <div className="mt-3">
                            <p className="lead">Credits based on amount of NFTs in your portfolio</p>
                            <div className="container">
                              <div className="row custom-border rounded p-3">
                              <div className="col-12 mb-1 text-center">
                                <strong>The total number of NFTs from all partner collections in your wallet</strong>
                              </div>
                              <div className="col-12 text-center">
                                 <i className="bi bi-diagram-3 fs-2 text-center text-secondary"></i>
                              </div>
                              <div className="col-12 text-center">
                                <strong>Individual multiplier adjusted to each collection
                                </strong>
                                </div>
                              </div>
                              <div className="col-12 mt-2 small text-secondary">
                              Below, you can see the current number of tokens you own and multiplier.
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12">
                        <h3 className="mb-4"><i className="bi bi-backpack2 fs-2 me-3"></i>Your NFT portfolio</h3>
                        {collectionAddresses.map((collection, index) => (
                          collection.collectionAddr ? (
                            <TokenDisplay 
                              key={index}
                              starsAddress={starsAddress}
                              collectionAddr={collection.collectionAddr}
                              title={collection.title}
                              cover={collection.cover}
                              link={collection.link}
                              multiplier={collection.multiplier}
                              updateTotalCount={(count) => updateTotalCount(collection.collectionAddr, count)}
                              updateIsOwned={(owned) => updateIsOwned(collection.collectionAddr, owned)}
                            />
                          ) : null
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          } />

          <Route path="/collections" element={
            <div className="container-fluid p-0 pt-4">
              {ownershipProved ? (
                <>
                <div className="row">
                    <div className="col-4">
                      <AddCollectionForm 
                        starsAddress={starsAddress}
                        showMessage={(msg, type) => Helpers.showMessage(setMessage, setMessageType, msg, type)}
                        refreshCollectionList={() => refreshCollectionList(starsAddress, setCollections)}
                        totalCount={totalCount}
                        ownedCollectionsCount={ownedCollectionsCount}
                      />
                      </div>
                    <div className="col-8">
                      <CollectionList 
                        collections={collections}
                        setMessage={setMessage}
                        setMessageType={setMessageType}
                        starsAddress={starsAddress}
                        setCollections={setCollections}
                        ownedCollectionsCount={ownedCollectionsCount}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-10">
                  <div className="alert alert-warning mt-3 d-flex align-items-center" role="alert">
                      <i className="bi bi-exclamation-triangle-fill me-2"></i>
                      <small>Wallet session has ended or wallet address has changed. Please reconnect wallet.</small>
                    </div>
                  </div>
                </div>
              )}
            </div>
          } />

          <Route path="collection/edit/files/:collectionId" element={
            ownershipProved ? (
              <div className="container-fluid p-0">
                <CollectionFileUpload 
                  totalCount={totalCount}
                  ownedCollectionsCount={ownedCollectionsCount}
                  setMessage={setMessage}
                  setMessageType={setMessageType}
                />
              </div>
            ) : (
              <div className="row">
              <div className="col-10">
              <div className="alert alert-warning mt-3 d-flex align-items-center" role="alert">
                  <i className="bi bi-exclamation-triangle-fill me-2"></i>
                  <small>Wallet session has ended or wallet address has changed. Please reconnect wallet.</small>
                </div>
              </div>
            </div>
        )
          } />

          <Route path="/collection/edit/:id" element={
            ownershipProved ? (
              <div className="container-fluid p-0">
                <EditCollection 
                  totalCount={totalCount}
                  ownedCollectionsCount={ownedCollectionsCount}
                />
              </div>
            ) : (
              <div className="row">
              <div className="col-10">
              <div className="alert alert-warning mt-3 d-flex align-items-center" role="alert">
                  <i className="bi bi-exclamation-triangle-fill me-2"></i>
                  <small>Wallet session has ended or wallet address has changed. Please reconnect wallet.</small>
                </div>
              </div>
            </div>
        )
          } />

          {/* Add a separate Route for Borrow */}
          <Route path="/borrow" element={
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-md-12">
                  <Borrow 
                    starsAddress={starsAddress} 
                    totalCount={totalCount}  // Add this line
                  />
                </div>
              </div>
            </div>
          } />

          {/* Add a separate Route for Renameizer with ownership check */}
          <Route path="/renameizer" element={
            <div className="container-fluid p-0">
              {ownershipProved ? (
                <div className="row">
                  <div className="col-md-12">
                    <Renameizer />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-10">
                    <div className="alert alert-warning mt-3 d-flex align-items-center" role="alert">
                      <i className="bi bi-exclamation-triangle-fill me-2"></i>
                      <small>Wallet session has ended or wallet address has changed. Please reconnect wallet.</small>
                    </div>
                  </div>
                </div>
              )}
            </div>
          } />

          {/* Add this new route before the closing Routes tag */}
          <Route path="/xmas" element={<XmasPage />} />

          {/* Keep the existing dynamic routes */}
          {Object.entries(routeComponents).map(([path, element]) => (
            <Route key={path} path={path} element={
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-md-12">
                    {element}
                  </div>
                </div>
              </div>
            } />
          ))}

          <Route path="/faq" element={<Faq />} /> 
        
        
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;