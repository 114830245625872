import { shortenAddress, copyToClipboard, baseConnectWallet, baseProveAccountOwnership, endSession, isWalletAvailable } from './walletUtils';

export const connectWallet = async (setStarsAddress, showMessageCallback) => {
  if (!isWalletAvailable('Keplr')) {
    console.warn('Keplr wallet not available');
    return;
  }
  
  return baseConnectWallet(
    window.keplr, 
    "stargaze-1", 
    setStarsAddress,
    (chainId) => window.keplr.getOfflineSigner(chainId),
    'Keplr',
    showMessageCallback
  );
};

export const proveAccountOwnership = async (setStarsAddress, setOwnershipProved, showMessageCallback) => {
  return baseProveAccountOwnership(
    window.keplr,
    "stargaze-1",
    setStarsAddress,
    setOwnershipProved,
    showMessageCallback,
    (chainId) => window.keplr.getOfflineSigner(chainId),
    'Keplr'
  );
};

export { shortenAddress, copyToClipboard, endSession };
