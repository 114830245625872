import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavLink = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li className="nav-item">
      <Link className={`nav-link fw-bold text-own ${isActive ? 'active' : ''}`} to={to}>
        {children}
      </Link>
    </li>
  );
};

export default NavLink;