import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import axios from 'axios';
import { showMessage } from '../ext/helpers';
import TraitManagement from './TraitManagement';
import UploadSection from './UploadSection';
import FilesList from './FilesList';
import { fetchFiles, fetchTraits, fetchAssignedTraits, fetchCollectionDetails } from './api';
import { BADART_URL } from '../config';
import JSZip from 'jszip';
import { setupWalletChangeHandlers } from '../wallets/walletUtils';

// Add setMessage and setMessageType to props
const CollectionFileUpload = ({ setFiles, totalCount, ownedCollectionsCount, setMessage, setMessageType }) => {
  const { collectionId } = useParams(); // Pobierz collectionId z adresu URL
  const navigate = useNavigate();  // Add this
  const [starsAddress, setStarsAddress] = useState(sessionStorage.getItem('starsAddress') || '');
  const [uploadedFiles, setUploadedFiles] = useState([]); // Stan do przechowywania listy plików
  // Remove the message state since we'll use the App.js one
  // const [message, setMessage] = useState(null);
  // Remove unused state
  // const [traitName, setTraitName] = useState('');
  // const [traitValue, setTraitValue] = useState('');
  const [traits, setTraits] = useState([]); // Stan do przechowywania listy cech
  const [assignedTraits, setAssignedTraits] = useState({}); // New state for tracking assigned traits
  const [selectedTrait, setSelectedTrait] = useState({}); // New state for tracking selected traits per file
  const [selectedFiles, setSelectedFiles] = useState([]); // New state for queued files
  const [isUploading, setIsUploading] = useState(false); // New state for upload status
  const [uploadProgress, setUploadProgress] = useState({}); // New state for tracking progress
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [isLoadingFiles, setIsLoadingFiles] = useState(true); // New state for loading
  const [collectionDetails, setCollectionDetails] = useState({
    name: '',
    description: ''
  });
  const [isCheckingOwnership, setIsCheckingOwnership] = useState(true);  // Add this
  const [isOwner, setIsOwner] = useState(false);  // Add this

  // Replace the old fetch functions with calls to the imported ones
  // Wrap functions in useCallback
  const loadFiles = useCallback(async () => {
    setIsLoadingFiles(true);
    try {
      const files = await fetchFiles(collectionId);
      setUploadedFiles(files);
    } catch (error) {
      console.error('Error loading files:', error);
    } finally {
      setIsLoadingFiles(false);
    }
  }, [collectionId]);

  const loadTraits = useCallback(async () => {
    try {
      const traitsData = await fetchTraits(collectionId);
      setTraits(traitsData);
    } catch (error) {
      console.error('Error loading traits:', error);
    }
  }, [collectionId]);

  const loadAssignedTraits = useCallback(async () => {
    try {
      const assignedTraitsData = await fetchAssignedTraits(collectionId);
      setAssignedTraits(assignedTraitsData);
    } catch (error) {
      console.error('Error loading assigned traits:', error);
    }
  }, [collectionId]);

  const loadCollectionDetails = useCallback(async () => {
    try {
      const details = await fetchCollectionDetails(collectionId, starsAddress);
      setCollectionDetails(details);
    } catch (error) {
      console.error('Error loading collection details:', error);
    }
  }, [collectionId]);

  // Add ownership check function
  const checkOwnership = useCallback(async () => {
    if (!starsAddress || !collectionId) return;

    try {
      const response = await fetch(`${BADART_URL}/check_ownership.php?collectionId=${collectionId}&walletAdd=${starsAddress}`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      if (!data.success) throw new Error(data.message);
      
      if (!data.isOwner) {
        showMessage(setMessage, setMessageType, 'You do not have permission to view this collection', 'error');
        navigate('/collections');  // Redirect to collections page
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error checking ownership:', error);
      showMessage(setMessage, setMessageType, 'Error verifying collection ownership', 'error');
      navigate('/collections');  // Redirect to collections page
      return false;
    } finally {
      setIsCheckingOwnership(false);
    }
  }, [starsAddress, collectionId, navigate, setMessage, setMessageType]);

  // Modify useEffect to check ownership first
  useEffect(() => {
    const initializeComponent = async () => {
      if (!starsAddress) {
        const addr = sessionStorage.getItem('starsAddress');
        if (addr) setStarsAddress(addr);
      }

      const hasOwnership = await checkOwnership();
      if (hasOwnership) {
        setIsOwner(true);
        loadFiles();
        loadTraits();
        loadAssignedTraits();
        loadCollectionDetails();
      }
    };

    initializeComponent();
  }, [collectionId, starsAddress, checkOwnership, loadAssignedTraits, loadCollectionDetails, loadFiles, loadTraits]);

  // Update the wallet change effect
  useEffect(() => {
    const cleanup = setupWalletChangeHandlers(
      setStarsAddress,
      null, // setOwnershipProved is not needed here
      navigate,
      checkOwnership
    );
    return cleanup;
  }, [setStarsAddress, navigate, checkOwnership]);

  // Add helper function to get paginated files
  const getPaginatedFiles = () => {
    const limitedFiles = uploadedFiles.slice(0, totalCount);
    const start = (currentPage - 1) * pageSize;
    return limitedFiles.slice(start, start + pageSize);
  };

  // Add helper function to get total pages
  const getTotalPages = () => {
    return Math.ceil(Math.min(uploadedFiles.length, totalCount) / pageSize);
  };

  const handleFileDelete = async (fileName) => {
    try {
      const response = await axios.post(`${BADART_URL}/delete_file.php`, {
        collectionId,
        fileName,
        walletAdd: starsAddress
      });
      console.log('File deleted successfully:', response.data);
      await loadFiles(); // Add this line to refresh the file list
      showMessage(setMessage, setMessageType, 'File deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting file:', error);
      showMessage(setMessage, setMessageType, 'Error deleting file', 'error');
    }
  };

  // Add new function to handle trait assignment
  const handleAssignTrait = async (fileId, valueId) => {
    if (!valueId) {
      showMessage(setMessage, setMessageType, 'Please select a trait value', 'error');
      return;
    }
  
    console.log('Starting trait assignment with:', { fileId, valueId });
  
    try {
      const response = await axios.post(`${BADART_URL}/trait_to_file.php`, {
        file_id: fileId,
        value_id: valueId
      });
  
      console.log('Server response:', response.data);
  
      if (response.data.success) {
        console.log('Assignment successful, fetching updated traits...');
        await loadAssignedTraits();
        setSelectedTrait(prev => ({ ...prev, [fileId]: '' }));
        showMessage(setMessage, setMessageType, response.data.message, 'success');
      } else {
        console.error('Server returned error:', response.data.message);
        showMessage(setMessage, setMessageType, response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error in handleAssignTrait:', error);
      console.error('Error response:', error.response?.data);
      showMessage(setMessage, setMessageType, error.response?.data?.message || 'Error assigning trait value', 'error');
    }
  };

  // Add new function to handle trait removal
  const handleRemoveTrait = async (fileId, valueId) => {
    try {
      const response = await axios.post(`${BADART_URL}/remove_trait_from_file.php`, {
        file_id: fileId,
        value_id: valueId
      });
      if (response.data.success) {
        await loadAssignedTraits();
        showMessage(setMessage, setMessageType, 'Trait removed successfully', 'success');
      }
    } catch (error) {
      console.error('Error removing trait:', error);
      showMessage(setMessage, setMessageType, 'Error removing trait', 'error');
    }
  };

  // Add this helper function to group traits
  const groupTraitsByMainTrait = (traits) => {
    const grouped = {};
    traits.forEach(trait => {
      if (!grouped[trait.trait_name]) {
        grouped[trait.trait_name] = [];
      }
      grouped[trait.trait_name].push(trait);
    });
    return grouped;
  };

  const handleJsonDownload = (file, index) => {
    const fileTraits = assignedTraits[file.id] || [];
    const attributes = {};
    
    // Group traits by main trait name
    fileTraits.forEach(trait => {
      attributes[trait.trait_name] = trait.value;
    });

    const jsonData = {
      attributes: Object.entries(attributes).map(([trait_name, value]) => ({
        [trait_name]: value
      })),
      description: collectionDetails.description,
      name: collectionDetails.name
    };

    // Create and download file
    const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${index}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Add new helper function to get traits that can be assigned
  const getAvailableTraits = (fileId) => {
    const fileTraits = assignedTraits[fileId] || [];
    const assignedTraitNames = new Set(fileTraits.map(trait => trait.trait_name));
    
    return traits.filter(trait => !assignedTraitNames.has(trait.trait_name));
  };

  // Add this function after handleJsonDownload
  const handleBulkJsonDownload = async () => {
    const zip = new JSZip();
    const limitedFiles = uploadedFiles.slice(0, totalCount);

    limitedFiles.forEach((file, index) => {
      const fileTraits = assignedTraits[file.id] || [];
      const attributes = {};
      
      // Group traits by main trait name
      fileTraits.forEach(trait => {
        attributes[trait.trait_name] = trait.value;
      });

      const jsonData = {
        attributes: Object.entries(attributes).map(([trait_name, value]) => ({
          [trait_name]: value
        })),
        description: collectionDetails.description,
        name: collectionDetails.name
      };

      // Add JSON file to zip
      zip.file(`${index + 1}.json`, JSON.stringify(jsonData, null, 2));
    });

    // Generate and download zip file
    const blob = await zip.generateAsync({ type: 'blob' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${collectionDetails.name || 'collection'}_metadata.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Add loading state to the return statement
  if (isCheckingOwnership) {
    return (
      <div className="text-center py-5">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Verifying ownership...</span>
        </div>
        <div className="mt-2 text-primary">Verifying ownership...</div>
      </div>
    );
  }

  if (!isOwner) {
    return null;  // Component will unmount due to navigation
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-9 col-md-8 mb-5 form-group">
          <UploadSection 
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            uploadProgress={uploadProgress}
            setUploadProgress={setUploadProgress}
            totalCount={totalCount}
            collectionId={collectionId}
            starsAddress={starsAddress}
            setMessage={setMessage}
            setMessageType={setMessageType}
            fetchFiles={loadFiles}
          />
          {isLoadingFiles ? (
            <div className="text-center py-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <div className="mt-2 text-primary">Loading files...</div>
            </div>
          ) : (
            <FilesList
              uploadedFiles={uploadedFiles}
              assignedTraits={assignedTraits}
              selectedTrait={selectedTrait}
              setSelectedTrait={setSelectedTrait}
              handleRemoveTrait={handleRemoveTrait}
              handleAssignTrait={handleAssignTrait}
              handleJsonDownload={handleJsonDownload}
              handleFileDelete={handleFileDelete}
              getAvailableTraits={getAvailableTraits}
              groupTraitsByMainTrait={groupTraitsByMainTrait}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              getPaginatedFiles={getPaginatedFiles}
              getTotalPages={getTotalPages}
              starsAddress={starsAddress}
              collectionId={collectionId}
              setMessage={setMessage}
              setMessageType={setMessageType}
              fetchAssignedTraits={loadAssignedTraits}
            />
          )}
        </div>
        <div className="col-3 col-md-4">
          <TraitManagement 
            traits={traits}
            setTraits={setTraits}
            setMessage={setMessage}
            setMessageType={setMessageType}
            collectionId={collectionId}
            fetchAssignedTraits={loadAssignedTraits}
            fetchTraits={loadTraits}
          />

          <div className='col-12 custom-border rounded p-3 mb-4'>
            <h5 className="mb-3">Done working on your collection?</h5>
            {uploadedFiles.length > 0 && (

              <button 
                className="btn btn-dark"
                onClick={handleBulkJsonDownload}
              >
                <i className="bi bi-file-earmark-zip me-2"></i>
                Download All Metadata <sup>JSON</sup> files
              </button>
            )}
            </div>
        </div>

      </div>
      <div className="uploaded-files">
      </div>
    </div>
  );
};

export default CollectionFileUpload;