import React from 'react';
import TokenDisplay from './TokenDisplay';
import collectionAddresses from './collections/collectionAddresses';
import forArtistsImage from './img/for-artists.jpg';
import forCollectionsImage from './img/for-collections.jpg';
import forRetailImage from './img/for-retail.jpg';
import badArtLogo from './img/logo.gif';
import empty_bg from './img/bg.gif';

const HomePage = () => {
return (
    <div className="container-flex pt-0">
        <div className="row mb-4 pt-1 pb-2 pt-4">
            <div className="col-12 col-md-9 border-start border-dark border-3 ps-4">
                <div className='row mb-1'>
                    <div className="col-auto">
                        <p className="bg-dark custom-size text-white ps-3 pe-4 rounded">CREATE NFT</p>
                    </div>
                    <div className="col-4">
                        <p className="fs-1 text-uppercase lh-1 text-secondary">assembly instructions</p>
                    </div>
                </div>
                <div className="row">
                    <p className="h5 col-12 col-md-9">Only Bad Art has created a number of utility tools to help non coded artists to easy create NFT collections or pleb to extend the lifecycle of their collections.</p>
                </div>
            </div>
            <div className="col-6 col-md-3">
                <div className="row justify-content-end me-2">
                    <div className="col-6 pe-3 me-2 mb-1">
                        <a href='https://only-bad.art' target='_blank' rel='noreferrer'><img src={badArtLogo} alt="Only Bad Art" className="img-fluid" /></a>
                    </div>
                    <div className="col-3 border-start border-dark border-2 d-flex align-items-center justify-content-end me-5">
                            <a href='https://x.com/only_bad_art' target='_blank' rel='noreferrer' className='text-dark h1'><i className="bi bi-twitter-x"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div className="row justify-content-center">
            <div className="col-12 col-md-4 d-flex flex-column">
                <h2 className='fw-bolder'>For artists</h2>
                <div className='custom-border rounded p-3 my-2 flex-grow-1'>
                    <img src={forArtistsImage} alt="artist" className="img-fluid mx-auto d-block" />
                    <h5 className='mt-4'>Create NFTs Without Coding!</h5>
                    <p>Bad Art App app makes it easy to generate metadata for your NFTs files. Chill and focus on your art, and let us handle the rest!</p>
                </div>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column">
                <h2 className='fw-bolder'>For retail</h2>
                <div className='custom-border rounded p-3 my-2 flex-grow-1'>
                <img src={forRetailImage} alt="Retial" className="img-fluid mx-auto d-block" />
                <h5 className='mt-4'>Take profits!</h5>
                    <p>Sell ... Buy... Speculate on  NFT values and gain a market edge or whatever makes you happy...</p>
                </div>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column">
                <h2 className='fw-bolder'>For NFT projects</h2>
                <div className='custom-border rounded p-3 my-2 flex-grow-1'>
                    <img src={forCollectionsImage} alt="Collections" className="img-fluid mx-auto d-block" />
                    <h5 className='mt-4'>Add Utility to Your Project!</h5>
                    <p>Your NFTs can become the key to increasing limits in our app. Apply and let the hodlers decide if new value should be brought to your project.</p>
                </div>
            </div>
        </div>


        <div className="row custom-portfolio mt-4">
            <div className="col-12">
                <h4 className='mb-3'>Recently added NFT collections</h4>
                <div className="d-flex flex-wrap">
                    {collectionAddresses.map((collection, index) => (
                        collection.collectionAddr ? (
                            <TokenDisplay
                                key={index}
                                starsAddress="" // Changed from null to empty string
                                collectionAddr={collection.collectionAddr}
                                title={collection.title}
                                cover={collection.cover}
                                link={collection.link}
                                multiplier={collection.multiplier}
                                updateTotalCount={() => {}} // Add empty callback
                                updateIsOwned={() => {}} // Add empty callback
                                hideTokenCount={true} // Hide token count for homePage.js
                            />
                        ) : null
                    ))}
                    <div className="col-5 col-md-2 float-start">
                        <article className={'card text-center custom-border border-bottom-0'}>
                            <div className="card-body p-0 d-flex flex-column">
                                <img className="rounded-top img-fluid" src={empty_bg} alt="empty" loading="lazy" />
                                <div className="card-body p-0 d-flex flex-column">
                                     <p className="card-title fw-bold mt-4 mb-5 fs-5">Your collection</p>
                                     <a href="/#/apply" className="btn bg-dark col-12 text-white rounded-top-0" role="button">Apply!</a>                        
                                  </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>

        <div className="row mt-5">
            <div className="col-12">
            </div>
       </div>


    </div>
);
};

export default HomePage;
