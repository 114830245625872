import React, { useState } from 'react';
import axios from 'axios';
import { showMessage } from '../ext/helpers';
import { getOrderedFileIds } from './api';  // Add this import back
import { BADART_URL } from '../config';
import ConfirmationToast from '../components/ConfirmationToast';

const TraitManagement = ({ traits, setTraits, setMessage, setMessageType, collectionId, fetchAssignedTraits, fetchTraits }) => {
  const [traitName, setTraitName] = useState('');
  const [traitValue, setTraitValue] = useState('');
  const [selectedMainTrait, setSelectedMainTrait] = useState(null);

  // New state for batch assignment
  const [fromFile, setFromFile] = useState('');
  const [toFile, setToFile] = useState('');
  const [selectedTraitForBatch, setSelectedTraitForBatch] = useState('');
  const [selectedValueForBatch, setSelectedValueForBatch] = useState('');
  const [isBatchAssigning, setIsBatchAssigning] = useState(false);

  // Add new state for delete confirmations
  const [pendingTraitDelete, setPendingTraitDelete] = useState(null);
  const [pendingValueDelete, setPendingValueDelete] = useState(null);

  // Add new state for progress bar
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const BATCH_SIZE = 100; // Number of files to process in each request

  const processBatch = async (files, valueId) => {
    const assignments = files.map(fileId => ({
      file_id: fileId,
      value_id: parseInt(valueId)
    }));

    const response = await axios.post(`${BADART_URL}/trait_to_file.php`, {
      assignments
    });

    return response.data;
  };

  const handleMainTraitSubmit = async (event) => {
    event.preventDefault();
    try {
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
      const response = await axios.post(`${BADART_URL}/add_main_trait.php`, {
        collection_id: collectionId,
        trait_name: traitName,
        creationDate: formattedDate,
      });
      if (response.data.success) {
        setTraits(prevTraits => [...prevTraits, { ...response.data.trait, values: [] }]);
        setTraitName('');
        showMessage(setMessage, setMessageType, 'Main trait added successfully', 'success');
      } else {
        showMessage(setMessage, setMessageType, response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error adding main trait:', error);
      const errorMessage = error.response?.data?.message || 'Error adding main trait';
      showMessage(setMessage, setMessageType, errorMessage, 'error');
    }
  };

  const handleTraitValueSubmit = async (event) => {
    event.preventDefault();
    if (!selectedMainTrait) return;

    try {
      const response = await axios.post(`${BADART_URL}/add_trait_value.php`, {
        main_trait_id: selectedMainTrait,
        value: traitValue
      });
      if (response.data.success) {
        await fetchTraits();
        setTraitValue('');
        showMessage(setMessage, setMessageType, 'Trait value added successfully', 'success');
      } else {
        showMessage(setMessage, setMessageType, response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error adding trait value:', error);
      const errorMessage = error.response?.data?.message || 'Error adding trait value';
      showMessage(setMessage, setMessageType, errorMessage, 'error');
    }
  };

  // Modify delete handlers
  const handleMainTraitDelete = async (traitId) => {
    setPendingTraitDelete(traitId);
  };

  const handleTraitValueDelete = async (valueId) => {
    setPendingValueDelete(valueId);
  };

  // Add confirmation handlers
  const confirmTraitDelete = async () => {
    try {
      const response = await axios.post(`${BADART_URL}/del_main_trait.php`, {
        trait_id: pendingTraitDelete
      });
      if (response.data.success) {
        setTraits(prevTraits => prevTraits.filter(trait => trait.id !== pendingTraitDelete));
        await fetchAssignedTraits();
        showMessage(setMessage, setMessageType, response.data.message, 'success');
      }
    } catch (error) {
      console.error('Error deleting main trait:', error);
      showMessage(setMessage, setMessageType, 'Error deleting main trait', 'error');
    } finally {
      setPendingTraitDelete(null);
    }
  };

  const confirmValueDelete = async () => {
    try {
      const response = await axios.post(`${BADART_URL}/del_trait_value.php`, {
        value_id: pendingValueDelete
      });
      if (response.data.success) {
        await fetchTraits();
        await fetchAssignedTraits();
        showMessage(setMessage, setMessageType, response.data.message, 'success');
      }
    } catch (error) {
      console.error('Error deleting trait value:', error);
      showMessage(setMessage, setMessageType, 'Error deleting trait value', 'error');
    } finally {
      setPendingValueDelete(null);
    }
  };

  // Add new handler for batch trait assignment
  const handleBatchTraitSubmit = async (event) => {
    event.preventDefault();
    setIsBatchAssigning(true);
    setIsProcessing(true);
    setProgress(0);
    
    // Validate range and convert to numbers
    const from = parseInt(fromFile);
    const to = parseInt(toFile);
    
    // Basic validation
    if (isNaN(from) || isNaN(to) || from <= 0 || to <= 0 || from > to) {
      showMessage(setMessage, setMessageType, 'Please enter valid file numbers', 'error');
      setIsBatchAssigning(false);
      setIsProcessing(false);
      return;
    }

    if (!selectedTraitForBatch || !selectedValueForBatch) {
      showMessage(setMessage, setMessageType, 'Please select both trait and value', 'error');
      setIsBatchAssigning(false);
      setIsProcessing(false);
      return;
    }

    try {
      showMessage(setMessage, setMessageType, 'Assigning trairs in progress...', 'info');

      // Get ordered file IDs mapping
      const orderedFiles = await getOrderedFileIds(collectionId);
      console.log('Ordered files mapping:', orderedFiles); // Debug log

      // Get the actual file IDs for the requested range
      const targetFiles = orderedFiles
        .filter(file => file.displayId >= from && file.displayId <= to)
        .map(file => file.actualId);

      if (targetFiles.length === 0) {
        const availableRange = `1-${orderedFiles.length}`;
        showMessage(
          setMessage, 
          setMessageType, 
          `No valid files found in range ${from}-${to}. Available range: ${availableRange}`,
          'error'
        );
        setIsBatchAssigning(false);
        setIsProcessing(false);
        return;
      }

      // Split files into batches
      const batches = [];
      for (let i = 0; i < targetFiles.length; i += BATCH_SIZE) {
        batches.push(targetFiles.slice(i, i + BATCH_SIZE));
      }

      let successCount = 0;
      let errorCount = 0;
      let errors = [];

      // Process batches
      for (let i = 0; i < batches.length; i++) {
        const batch = batches[i];
        try {
          const result = await processBatch(batch, selectedValueForBatch);
          
          successCount += result.successful;
          errorCount += result.failed;
          
          // Add any errors to our error list
          result.results
            .filter(r => !r.success)
            .forEach(r => {
              const displayId = orderedFiles.find(f => f.actualId === r.file_id)?.displayId;
              errors.push(`File ${displayId}: ${r.message}`);
            });

          // Update progress based on total files processed
          const filesProcessed = (i + 1) * BATCH_SIZE;
          const currentProgress = Math.round((filesProcessed / targetFiles.length) * 100);
          setProgress(Math.min(currentProgress, 100));

        } catch (error) {
          console.error('Batch processing error:', error);
          errorCount += batch.length;
          errors.push(`Batch ${i + 1} failed: ${error.message}`);
        }
      }

      // Refresh traits
      await fetchAssignedTraits();

      // Show result message
      const message = `Batch assignment completed: ${successCount} successful, ${errorCount} failed${errors.length ? '\nErrors: ' + errors.slice(0, 3).join(', ') + (errors.length > 3 ? '...' : '') : ''}`;
      
      showMessage(
        setMessage,
        setMessageType,
        message,
        errorCount === 0 ? 'success' : 'warning'
      );

      // Reset form
      setFromFile('');
      setToFile('');
      setSelectedTraitForBatch('');
      setSelectedValueForBatch('');

    } catch (error) {
      console.error('Error in batch assignment:', error);
      showMessage(setMessage, setMessageType, 'Error processing batch assignment', 'error');
    } finally {
      setIsBatchAssigning(false);
      setIsProcessing(false);
      setProgress(0);
    }
  };

  return (
    <>
      {/* Add confirmation toasts */}
      {pendingTraitDelete && (
        <ConfirmationToast
          message="Are you sure you want to delete this trait? All associated values and assignments will be removed."
          onConfirm={confirmTraitDelete}
          onCancel={() => setPendingTraitDelete(null)}
        />
      )}
      {pendingValueDelete && (
        <ConfirmationToast
          message="Are you sure you want to delete this trait value? All associated assignments will be removed."
          onConfirm={confirmValueDelete}
          onCancel={() => setPendingValueDelete(null)}
        />
      )}

      <div className="form-group custom-border p-3 mb-4 rounded">
        <h5 className='mb-3'>Add new trait</h5>
        <form onSubmit={handleMainTraitSubmit}>
          <input
            type="text"
            placeholder='Enter trait name e.g. Background'
            className="form-control mb-2"
            value={traitName}
            maxLength={255}
            onChange={(e) => setTraitName(e.target.value)}
            required
          />
          <button type="submit" className="btn btn-dark">Add trait</button>
        </form>
      </div>

      <div className="form-group custom-border p-3 mb-4 rounded">
        <h5 className='mb-3'>Add Trait Value</h5>
        <form onSubmit={handleTraitValueSubmit}>
          <select 
            className="form-select mb-2"
            value={selectedMainTrait || ''}
            onChange={(e) => setSelectedMainTrait(e.target.value)}
            required
          >
            <option value="">Select trait...</option>
            {traits.map(trait => (
              <option key={trait.id} value={trait.id}>{trait.trait_name}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder='Enter trait value e.g. Red'
            className="form-control mb-2"
            value={traitValue}
            onChange={(e) => setTraitValue(e.target.value)}
            required
          />
          <button type="submit" className="btn btn-dark">Add Value</button>
        </form>
      </div>

      <ul className="list-group bg-transparent mb-4">
        {traits.map(trait => (
          <li key={trait.id} className="list-group-item bg-light">
            <div className="d-flex justify-content-between align-items-start">
              <strong>{trait.trait_name}</strong>
              <button 
                className="btn btn-danger btn-sm"
                onClick={() => handleMainTraitDelete(trait.id)}
              >
                <i className="bi bi-trash"></i>
              </button>
            </div>
            <div className="mt-2 d-flex flex-wrap gap-2">
              {trait.values.map(value => (
                <span key={value.id} className="badge bg-secondary d-flex align-items-center">
                  {value.value.split(' ').slice(0, 3).join(' ')}{value.value.split(' ').length > 3 ? '...' : ''}
                  <button 
                    className="btn-close btn-close-white ms-2"
                    style={{ fontSize: '0.65rem' }}
                    onClick={() => handleTraitValueDelete(value.id)}
                    aria-label="Delete trait value"
                  ></button>
                </span>
              ))}
            </div>
          </li>
        ))}
      </ul>


      <div className="form-group custom-border p-3 mb-4 rounded">
        <h5 className='mb-3'>Batch assign trait</h5>
        <form onSubmit={handleBatchTraitSubmit}>
          <div className="row mb-2">
            <div className="col">
              <input
                type="number"
                placeholder="From file #"
                className="form-control"
                value={fromFile}
                onChange={(e) => setFromFile(e.target.value)}
                required
                min="1"
              />
            </div>
            <div className="col">
              <input
                type="number"
                placeholder="To file #"
                className="form-control"
                value={toFile}
                onChange={(e) => setToFile(e.target.value)}
                required
                min="1"
              />
            </div>
          </div>
          <select 
            className="form-select mb-2"
            value={selectedTraitForBatch || ''}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedTraitForBatch(value ? Number(value) : '');
              setSelectedValueForBatch('');
            }}
            required
          >
            <option value="">Select trait...</option>
            {traits.map(trait => (
              <option key={trait.id} value={trait.id}>{trait.trait_name}</option>
            ))}
          </select>
          <select
            className="form-select mb-2"
            value={selectedValueForBatch || ''}
            onChange={(e) => setSelectedValueForBatch(e.target.value ? Number(e.target.value) : '')}
            required
            disabled={!selectedTraitForBatch}
          >
            <option value="">Select trait value...</option>
            {selectedTraitForBatch && traits
              .find(t => t.id === selectedTraitForBatch)?.values
              .map(value => (
                <option key={value.id} value={value.id}>
                  {value.value.split(' ').slice(0, 3).join(' ')}{value.value.split(' ').length > 3 ? '...' : ''}
                </option>
              ))}
          </select>
          
          {/* Add progress bar */}
          {isProcessing && (
            <div className="progress mb-3">
              <div 
                className="progress-bar" 
                role="progressbar" 
                style={{ width: `${progress}%` }}
                aria-valuenow={progress} 
                aria-valuemin="0" 
                aria-valuemax="100"
              >
                {progress}%
              </div>
            </div>
          )}
          
          <button 
            type="submit" 
            className="btn btn-dark" 
            disabled={isBatchAssigning}
          >
            {isBatchAssigning ? 'Processing...' : 'Batch Assign'}
          </button>
        </form>
      </div>

    </>
  );
};

export default TraitManagement;
