import React from 'react';

const Footer = () => {
    return (
        <footer style={{ textAlign: 'left', background: 'none' }}>
            <p style={{ color: '#D3D3D3', paddingLeft: '2em', fontSize: '10pt' }}>&copy; {new Date().getFullYear()} Made on a blunt in the underground.</p>
            {/* <img src="https://badart.app/logo.gif"  alt="Only Bad Art" /> */}
        </footer>
    );
};

export default Footer;
