import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as KeplrIntegration from '../wallets/KeplrIntegration';
import * as LeapIntegration from '../wallets/LeapIntegration';
import { toggleButtonsVisibility } from '../ext/helpers';
import { showMessage, endSession } from '../wallets/walletUtils';

const Header = ({ 
  ownershipProved, 
  starsAddress, 
  setStarsAddress, 
  setOwnershipProved, 
  setMessage, 
  setMessageType,
  setTotalCount 
}) => {
  const navigate = useNavigate();
  const activeWallet = sessionStorage.getItem('activeWallet');
  const [darkMode, setDarkMode] = React.useState(localStorage.getItem('darkMode') === 'true');

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  // Use the correct wallet integration based on active wallet type
  const getActiveWalletIntegration = () => {
    return activeWallet === 'Leap' ? LeapIntegration : KeplrIntegration;
  };

  const handleEndSession = () => {
    sessionStorage.removeItem('activeWallet'); // Clear active wallet
    endSession(
      setStarsAddress, 
      setOwnershipProved, 
      null,
      (msg, type) => showMessage(setMessage, setMessageType, msg, type),
      navigate
    );
  };

  return (
    <div className="row mb-3">
      <div className="col-5 rounded-top ms-1 pt-3">
        <h2 className="logo"><span className='text-secondary'>bad</span>art <sup>APP</sup></h2>
      </div>
      
      <div className="col p-0">

        <div className="bg-light float-end pb-2">
          
          {ownershipProved && (
            <div className="d-inline ms-1 font-small">
              <small className='me-2 ms-3'>
                {starsAddress ? getActiveWalletIntegration().shortenAddress(starsAddress) : 'Not connected'}
              </small>
              <button className='btn p-0' onClick={() => getActiveWalletIntegration().copyToClipboard(starsAddress)}>
                <i className="bi bi-copy text-dark me-3" id='copy_add'></i>
              </button>
            </div>
          )}

          {!ownershipProved ? (
            <div className="btn-group">
              <button 
                className="btn bg-dark ms-2 loginBtnNot text-white d-inline d-none keplrButton"
                onClick={() => KeplrIntegration.proveAccountOwnership(
                  setStarsAddress, 
                  setOwnershipProved,
                  (msg, type) => showMessage(setMessage, setMessageType, msg, type)
                )}>
                Keplr
              </button>
              <button 
                className="btn bg-dark ms-2 loginBtnNot text-white d-inline d-none leapButton"
                onClick={() => LeapIntegration.proveAccountOwnership(
                  setStarsAddress, 
                  setOwnershipProved,
                  (msg, type) => showMessage(setMessage, setMessageType, msg, type)
                )}>
                Leap
              </button>
              <button
                className="btn bg-dark ms-2 loginBtnNot text-white d-inline showButtons"
                onClick={() => toggleButtonsVisibility()}>
                Connect wallet
              </button>
            </div>
          ) : (
            <div className="btn-group">
              <button 
                className="btn btn-secondary logoutBtn text-white d-inline" 
                onClick={handleEndSession}>
                <i className="bi bi-box-arrow-right"></i>
              </button>
            </div>
          )}
        </div>
        {ownershipProved && (
        <a href='#/tickets' className='float-end me-3 pt-2 d-none'>
          <i className="fs-5 bi bi-bell-fill text-dark"></i>
          {/* <sup className="fs-9 badge rounded-pill badge-notification bg-danger">1</sup> */}
        </a>
        )}
          {/* <button
            className="theme-toggle nav-link d-inline float-end me-2"
            onClick={() => setDarkMode(!darkMode)}
            aria-label="Toggle dark mode"
          >
            {darkMode ? (
              <i className="bi bi-sun-fill"></i>
            ) : (
              <i className="bi bi-moon-fill"></i>
            )}
          </button> */}
      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default Header;