import React, { useState } from 'react';
import { BADART_URL } from '../config';


const TicketSystem = () => {
  const [ticket, setTicket] = useState({
    title: '',
    description: '',
    priority: 'low',
    status: 'open'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BADART_URL}/create-ticket.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ticket),
        credentials: 'include'
      });

      const data = await response.json();
      
      if (response.ok) {
        alert(data.message);
        setTicket({
          title: '',
          description: '',
          priority: 'low',
          status: 'open'
        });
      } else {
        throw new Error(data.message || 'Failed to create ticket');
      }
    } catch (error) {
      console.error('Error creating ticket:', error);
      alert('Failed to create ticket: ' + error.message);
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Create New Ticket</h2>
      <form onSubmit={handleSubmit} className="needs-validation">
        <div className="mb-3">
          <label className="form-label">Title:</label>
          <input
            type="text"
            className="form-control"
            value={ticket.title}
            onChange={(e) => setTicket({...ticket, title: e.target.value})}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Description:</label>
          <textarea
            className="form-control"
            rows="3"
            value={ticket.description}
            onChange={(e) => setTicket({...ticket, description: e.target.value})}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Priority:</label>
          <select
            className="form-select"
            value={ticket.priority}
            onChange={(e) => setTicket({...ticket, priority: e.target.value})}
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Create Ticket</button>
      </form>
    </div>
  );
};

TicketSystem.propTypes = {
  // Add props here if needed in the future
};

export default TicketSystem;
