import React, { useState } from 'react';
import { resizeImage } from '../ext/helpers';
import { BADART_URL } from '../config';
import forCollectionsImage from '../img/for-collections.jpg';

const ThankYouScreen = () => (
    <div className="text-center py-5">
        <h2 className="mb-4">Thank You for Your Application!</h2>
        <div className="mb-4">
            <i className="bi bi-check-circle-fill text-success" style={{ fontSize: '4rem' }}></i>
        </div>
        <p className="lead mb-3">Your application has been successfully submitted.</p>
        <div className="alert alert-info" role="alert">
            <i className="bi bi-info-circle me-2"></i>
            Current Status: <strong>Pending Review</strong>
        </div>
        <p className="text-muted">
            We will review your application and get back to you soon.
        </p>
    </div>
);

const ApplyForm = ({ starsAddress }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        twitter: '',
        website: '',
        walletAdd: starsAddress || ''
    });
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateFile = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
            throw new Error('Invalid file type. Only JPG, PNG and GIF allowed');
        }

        if (file.size > maxSize) {
            throw new Error('File size too large. Maximum 5MB allowed');
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                validateFile(file);
                const resizedImage = await resizeImage(file, 300);
                setImageFile(resizedImage);
                setImagePreview(URL.createObjectURL(resizedImage));
            } catch (error) {
                setError(error.message);
                e.target.value = ''; // Reset file input
                setImageFile(null);
                setImagePreview('');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        // Validate required fields
        if (!formData.name || !formData.description || !imageFile) {
            setError('Please fill in all required fields');
            setLoading(false);
            return;
        }

        try {
            const formDataWithFile = new FormData();
            Object.keys(formData).forEach(key => {
                formDataWithFile.append(key, formData[key].trim());
            });
            formDataWithFile.append('coverImage', imageFile);

            const response = await fetch(`${BADART_URL}/submit_application.php`, {
                method: 'POST',
                body: formDataWithFile
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to submit application');
            }

            setSuccess(true);
            // Clear form data but don't reset the form since we won't show it anymore
            setFormData({
                name: '',
                description: '',
                twitter: '',
                website: '',
                walletAdd: starsAddress || ''
            });
            setImageFile(null);
            setImagePreview('');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-12 col-md-4  mb-4 mb-md-0">
                    <div className="card h-100 border-0 bg-transparent">
                        <div className="card-body border-0">
                            <h3 className="mb-4">No utility? No problem!</h3>
                            <p>Apply and let the hodlers decide if new value should be brought to your project.</p>
                            <div className="mb-4">
                            {/* <ul className="list-unstyled">
                                <li><i className="bi bi-check2 me-2"></i>Creative and unique artwork</li>
                                <li><i className="bi bi-check2 me-2"></i>Strong portfolio examples</li>
                                <li><i className="bi bi-check2 me-2"></i>Active community involvement</li>
                            </ul> */}
                            </div>
                            <div className="mb-4">
                                <img src={forCollectionsImage} alt="artist" className="img-fluid mx-auto d-block" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <div className="card custom-border bg-transparent">
                        <div className="card-body">
                            {error && (
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            )}
                            
                            {success ? (
                                <ThankYouScreen />
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            autocomplete="off"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            placeholder="Enter your name"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">Description</label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            rows="4"
                                            autoComplete="off"
                                            required
                                            placeholder="Tell us about yourself"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="twitter" className="form-label">Twitter</label>
                                        <div className="input-group">
                                            <span className="input-group-text">@</span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="twitter"
                                                name="twitter"
                                                autoComplete="off"
                                                value={formData.twitter}
                                                onChange={handleChange}
                                                placeholder="username"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="website" className="form-label">Website</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            id="website"
                                            name="website"
                                            autoComplete="off"
                                            value={formData.website}
                                            onChange={handleChange}
                                            placeholder="https://example.com"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="coverImage" className="form-label">Cover Image</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="coverImage"
                                            name="coverImage"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            required
                                        />
                                        {imagePreview && (
                                            <div className="mt-2">
                                                <img 
                                                    src={imagePreview} 
                                                    alt="Preview" 
                                                    className="img-thumbnail" 
                                                    style={{ maxHeight: '200px' }} 
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <input
                                        type="hidden"
                                        name="walletAdd"
                                        value={starsAddress || ''}
                                    />
                                    <div className="d-grid">
                                        <button 
                                            type="submit" 
                                            className="btn btn-dark btn-lg"
                                            disabled={loading}
                                        >
                                            {loading ? 'Submitting...' : 'Submit Application'}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplyForm;