import React from 'react';

const Randomizer = () => {
    return (
        <div className="container mt-5 mb-5 pb-5">
            <div className="row pt-5">
                <div className="col-12">
                    <h1 className="display-4 text-center mb-4 text-secondary">Coming Soon</h1>
                            <p className="text-center">This feature is under development.</p>
                </div>
            </div>
        </div>
    );
};

export default Randomizer;