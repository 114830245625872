/**
 * Copies text to clipboard and updates UI feedback
 * @param {string} text - Text to copy to clipboard
 */
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      document.getElementById('copy_add')?.classList.add('text-success');
    })
    .catch((error) => {
      console.error('Failed to copy to clipboard:', error.message);
    });
};

/**
 * Toggles visibility of wallet connection buttons
 */
export const toggleButtonsVisibility = () => {
  const elements = {
    showButtons: document.querySelector('.showButtons'),
    keplrButton: document.querySelector('.keplrButton'),
    leapButton: document.querySelector('.leapButton')
  };

  if (!elements.showButtons) return;

  const isHidden = elements.keplrButton?.classList.contains('d-none');
  const buttons = [elements.keplrButton, elements.leapButton];
  
  elements.showButtons.classList.toggle('bg-dark', !isHidden);
  elements.showButtons.classList.toggle('bg-secondary', isHidden);
  
  buttons.forEach(button => {
    if (button) {
      button.classList.toggle('d-none', !isHidden);
      button.classList.toggle('fade-in', isHidden);
    }
  });
};

/**
 * Shows a message for 5 seconds
 * @param {Function} setMessage - State setter for message
 * @param {Function} setMessageType - State setter for message type
 * @param {string} message - Message to display
 * @param {string} type - Message type (e.g., 'success', 'error')
 * @param {boolean} autoClose - Whether to auto-close the message (default: true)
 */
export const showMessage = (setMessage, setMessageType, message, type, autoClose = true) => {
  setMessage(message);
  setMessageType(type);
  if (autoClose) {
    setTimeout(() => {
      setMessage(null);
      setMessageType(null);
    }, 20000); // 5 seconds
  }
};

/**
 * Resizes and crops an image file to specified dimensions maintaining aspect ratio
 * @param {File} file - Original image file
 * @param {number} targetHeight - Desired height in pixels (optional)
 * @param {number} targetWidth - Desired width in pixels (optional)
 * @returns {Promise<Blob>} - Resized image as a blob
 */
export const resizeImage = (file, targetHeight = null, targetWidth = null) => {
  return new Promise((resolve, reject) => {
    // Check if file is valid
    if (!file || !(file instanceof File)) {
      reject(new Error('Invalid file object'));
      return;
    }

    const img = new Image();
    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // Calculate new dimensions
        let newWidth, newHeight;
        const aspectRatio = img.width / img.height;
        
        if (targetWidth) {
          newWidth = targetWidth;
          newHeight = targetWidth / aspectRatio;
        } else if (targetHeight) {
          newHeight = targetHeight;
          newWidth = targetHeight * aspectRatio;
        } else {
          newHeight = img.height;
          newWidth = img.width;
        }
        
        // Set canvas dimensions
        canvas.width = newWidth;
        canvas.height = newHeight;
        
        // Draw image onto canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        
        // Convert canvas to blob
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to create blob'));
            return;
          }
          
          const optimizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now()
          });
          
          URL.revokeObjectURL(img.src); // Clean up
          resolve(optimizedFile);
        }, file.type, 0.8);
      } catch (error) {
        URL.revokeObjectURL(img.src);
        reject(error);
      }
    };
    
    img.onerror = (error) => {
      URL.revokeObjectURL(img.src);
      reject(error || new Error('Image loading failed'));
    };

    // Create object URL from file
    try {
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
    } catch (error) {
      reject(new Error('Failed to create object URL'));
    }
  });
};