import React, { useState } from 'react';
import { resizeImage, showMessage } from '../ext/helpers';
import { BADART_URL } from '../config';
import axios from 'axios';

const UploadSection = ({
  uploadedFiles,
  setUploadedFiles,
  isUploading,
  setIsUploading,
  uploadProgress,
  setUploadProgress,
  totalCount,
  collectionId,
  starsAddress,
  setMessage,
  setMessageType,
  fetchFiles
}) => {
  const [currentBatch, setCurrentBatch] = useState(0);
  const [totalBatches, setTotalBatches] = useState(0);
  const [processedFiles, setProcessedFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const BATCH_SIZE = 15;
  const MAX_FILES = 10000;

  const getRemainingSlots = () => {
    return Math.max(0, Math.min(MAX_FILES, totalCount) - uploadedFiles.length);
  };

  const optimizeImage = async (file) => {
    try {
      const resizedImage = await resizeImage(file, undefined, 250);
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now()
              }));
            },
            file.type,
            0.7
          );
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(resizedImage);
      });
    } catch (error) {
      console.error('Error optimizing image:', error);
      return file;
    }
  };

  const processBatch = async (files) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files[]', file));
    formData.append('collectionId', collectionId);
    formData.append('walletAdd', starsAddress);

    try {
      const response = await axios.post(`${BADART_URL}/upload_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          files.forEach(file => {
            setUploadProgress(prev => ({
              ...prev,
              [file.name]: percentCompleted
            }));
          });
        }
      });

      return response.data?.uploadedFiles ? files.length : 0;
    } catch (error) {
      console.error('Error uploading batch:', error);
      return 0;
    }
  };

  const checkCreditsLimit = async (filesCount) => {
    try {
      const response = await axios.get(`${BADART_URL}/fetch.php?stars=${starsAddress}`);
      return response.data.credits;
    } catch (error) {
      console.error('Error checking credits:', error);
      showMessage(setMessage, setMessageType, 'Error checking upload limits', 'error');
      return 0;
    }
  };

  const handleFileSelection = async (files) => {
    const remainingSlots = getRemainingSlots();
    let filesArray = Array.from(files);
    
    // First check collection limit
    if (uploadedFiles.length >= MAX_FILES) {
      showMessage(setMessage, setMessageType, 
        `Collection cannot have more than ${MAX_FILES} files`,
        'error'
      );
      return;
    }

    // Check remaining slots
    if (filesArray.length > remainingSlots) {
      filesArray = filesArray.slice(0, remainingSlots);
    }

    // Check credits limit
    const availableCredits = await checkCreditsLimit(filesArray.length);
    if (filesArray.length > availableCredits) {
      filesArray = filesArray.slice(0, availableCredits);
      showMessage(
        setMessage, 
        setMessageType,
        `Selection automatically limited to ${availableCredits} files based on your available credits`,
        'warning'
      );
    }

    if (filesArray.length === 0) return;

    setIsUploading(true);
    setProcessedFiles(0);
    setTotalFiles(filesArray.length);
    const batches = [];
    
    // Split files into batches
    for (let i = 0; i < filesArray.length; i += BATCH_SIZE) {
      batches.push(filesArray.slice(i, i + BATCH_SIZE));
    }

    setTotalBatches(batches.length);
    let successCount = 0;

    try {
      // Process batches concurrently with a limit
      for (let i = 0; i < batches.length; i += 3) {
        const currentBatches = batches.slice(i, i + 3);
        setCurrentBatch(i + 1);
        
        const optimizedBatches = await Promise.all(
          currentBatches.map(async batch => {
            const optimizedFiles = await Promise.all(
              batch.map(file => optimizeImage(file))
            );
            const result = await processBatch(optimizedFiles);
            // Update processed files count
            setProcessedFiles(prev => prev + batch.length);
            return result;
          })
        );

        successCount += optimizedBatches.reduce((sum, count) => sum + count, 0);
      }

      await fetchFiles();
      showMessage(
        setMessage, 
        setMessageType,
        `Uploaded ${successCount} of ${filesArray.length} files`,
        successCount === filesArray.length ? 'success' : 'warning'
      );
    } catch (error) {
      console.error('Error in batch upload:', error);
      showMessage(setMessage, setMessageType, 'Error uploading files', 'error');
    } finally {
      setIsUploading(false);
      setUploadProgress({});
      setProcessedFiles(0);
      setTotalFiles(0);
      setCurrentBatch(0);
      setTotalBatches(0);
    }
  };

  // Calculate progress percentage
  const getProgressPercentage = () => {
    if (totalFiles === 0) return 0;
    return Math.round((processedFiles / totalFiles) * 100);
  };

  return (
    <div className="upload-section col-12 custom-border rounded p-3 mb-4">
      <input
        type="file"
        className="d-none"
        id="fileInput"
        multiple
        accept="image/*"
        onChange={(e) => handleFileSelection(e.target.files)}
        disabled={getRemainingSlots() === 0 || isUploading}
      />
      
      <div className="d-flex align-items-center gap-3">
        <div className='pt-0'>
          <span className="badge bg-light fs-6 p-0 rounded pe-3 p-2 text-dark">
            <span className='badge bg-success fs-6 me-2'>
              <i className="bi bi-check-all"></i> 
              {Math.min(uploadedFiles.length, Math.min(MAX_FILES, totalCount))}
            </span> 
            files uploaded
            <small className="text-muted ms-2">(Max: {MAX_FILES})</small>
            <span className='badge bg-danger fs-6 ms-2 me-2'>{getRemainingSlots()}</span> 
            remaining
          </span>
        </div>
        
        <label 
          htmlFor="fileInput" 
          className={`btn ${isUploading ? 'btn-secondary' : 'btn-dark'} mb-0 ms-auto ${getRemainingSlots() === 0 ? 'disabled' : ''}`}
        >
          {isUploading ? (
            <>
              <i className="bi bi-arrow-repeat me-2"></i>
              Uploading...
            </>
          ) : (
            <>
              <i className="bi bi-upload me-2"></i>
              Choose & Upload Files
            </>
          )}
        </label>
      </div>

      {isUploading && (
        <div className="upload-progress mt-3">
          <small className="text-muted d-block mb-2">
            Processing files: {processedFiles} of {totalFiles} 
          </small>
          <div className="progress">
            <div 
              className="progress-bar progress-bar-animated progress-bar-striped" 
              role="progressbar" 
              style={{ 
                width: `${getProgressPercentage()}%` 
              }}
              aria-valuenow={getProgressPercentage()} 
              aria-valuemin="0" 
              aria-valuemax="100"
            />
          </div>
          <div className="alert alert-warning mt-3 d-flex align-items-center" role="alert">
            <i className="bi bi-exclamation-triangle-fill me-2"></i>
            <small>Please do not close or refresh this page until the upload is complete.</small>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadSection;
