const CHECK_FLOOR_PRICE = true;
const LIMIT = 900;

// Helper function for floor price
async function fetchFloorPrice(collectionAddr) {
  try {
    const response = await fetch('https://graphql.mainnet.stargaze-apis.com/graphql', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `{ collection(address: "${collectionAddr}") { floorPrice } }`
      })
    });
    
    const result = await response.json();
    return result.data?.collection?.floorPrice || 0;
  } catch (error) {
    console.error('Error fetching floor price:', error);
    return 0;
  }
}

async function fetchTokens({ starsAddress, collectionAddr, offset = 0 }) {
  if (!collectionAddr) {
    throw new Error('Missing collection address');
  }

  // If no starsAddress is provided, only fetch floor price
  if (!starsAddress) {
    const floorPrice = await fetchFloorPrice(collectionAddr);
    return {
      tokens: [],
      count: 0,
      hasMore: false,
      floorPrice
    };
  }

  try {
    const response = await fetch('https://graphql.mainnet.stargaze-apis.com/graphql', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          query Tokens($owner: String!, $collectionAddr: String!, $limit: Int, $offset: Int) {
            tokens(owner: $owner, collectionAddr: $collectionAddr, limit: $limit, offset: $offset) {
              tokens {
                collection {
                  name
                }
                tokenId
                name
                owner {
                  address
                }
              }
            }
          }
        `,
        variables: {
          owner: starsAddress,
          collectionAddr: collectionAddr,
          limit: LIMIT,
          offset
        }
      })
    });

    const result = await response.json();
    const tokens = result.data?.tokens?.tokens || [];
    
    const data = {
      tokens,
      count: tokens.length,
      hasMore: tokens.length === LIMIT
    };

    // Remove the offset check to always fetch floor price
    if (CHECK_FLOOR_PRICE) {
      data.floorPrice = await fetchFloorPrice(collectionAddr);
    }

    return data;
  } catch (error) {
    throw new Error(`Failed to fetch tokens: ${error.message}`);
  }
}

/**
 * Fetches token count for a single collection
 * @param {string} starsAddress - Wallet address
 * @param {string} collectionAddr - Collection address
 * @returns {Promise<{count: number, floorPrice?: number}>}
 */
async function getTokenCount(starsAddress, collectionAddr) {
  const result = await fetchTokens({ starsAddress, collectionAddr });
  return {
    count: result.count,
    floorPrice: result.floorPrice
  };
}

export { fetchTokens, fetchFloorPrice, getTokenCount };