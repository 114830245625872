import { shortenAddress, copyToClipboard, baseConnectWallet, baseProveAccountOwnership, endSession, isWalletAvailable } from './walletUtils';

export const connectWallet = async (setStarsAddress, showMessageCallback) => {
  if (!isWalletAvailable('Leap')) {
    console.warn('Leap wallet not available');
    return;
  }
  
  return baseConnectWallet(
    window.leap, 
    "stargaze-1", 
    setStarsAddress,
    (chainId) => window.leap.getOfflineSigner(chainId),
    'Leap',
    showMessageCallback
  );
};

export const proveAccountOwnership = async (setStarsAddress, setOwnershipProved, showMessageCallback) => {
  return baseProveAccountOwnership(
    window.leap,
    "stargaze-1",
    setStarsAddress,
    setOwnershipProved,
    showMessageCallback,
    (chainId) => window.leap.getOfflineSigner(chainId),
    'Leap'
  );
};

export { shortenAddress, copyToClipboard, endSession };

